import React, { Component } from 'react'
import ReactPlayer from 'react-player'
import {
    Row,
    Col,
    Button,
    Container,
    Breadcrumb,
    BreadcrumbItem,
    UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu,
    Media
} from 'reactstrap';
import axios from 'axios'
import { nodeBaseURL } from 'ApiURL';
import { NotifyWrapper } from 'components/Alerts/Notify';

export default class VideoPlayback extends Component {
    constructor(props) {
        super(props);
        this.player = React.createRef();
        this.state = {
            playing: false,
            currentTime: 0,
            status: 'Click on Play',
            duration: 0,
            apiData: { 'Loading...': {} },
            showDataForThisMinute: 1,
            moveButtons: {
                start: 0,
                end: 10
            },
            hourList: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
            selectedHour: 10,
        }
        this._ismounted = true;
    }

    fetchData = (nextProps) => {
        var config = {
            method: 'get',
            url: `${nodeBaseURL}benchmark/list/${'TNDRC-MAATNIN001'}/day/${nextProps.selectedHour}`,
            headers: {}
        };

        axios(config)
            .then((response) => {
                this._ismounted && this.setState({
                    apiData: response.data,
                    selectedHour: nextProps.selectedHour
                });
            })
            .catch((error) => {
                console.log(error);
                NotifyWrapper.warning('We are facing some error! Please contact us at support@oureye.ai.')
            });
    }

    componentDidMount() {
        this._ismounted && this.fetchData(this.state);
    }

    componentWillUnmount() {
        this._ismounted = false;
    }

    render() {
        var keys = this.state.apiData[this.state.showDataForThisMinute] ? Object.keys(this.state.apiData[this.state.showDataForThisMinute]) : []
        // var length = keys.length;
        return (
            <div className="header bg-info pb-6">
                <Container fluid>
                    <div className="header-body">
                        <Row className="align-items-center py-4">
                            <Col lg="6" xs="7">
                                <h6 className="h2 text-white d-inline-block mb-0">
                                    {this.props.name}
                                </h6>{" "}
                                <Breadcrumb
                                    className="d-none d-md-inline-block ml-md-4"
                                    listClassName="breadcrumb-links breadcrumb-dark"
                                >
                                    <BreadcrumbItem>
                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                            <i className="fas fa-home" />
                                        </a>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem>
                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                            {'Benchmarking Tool'}
                                        </a>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem aria-current="page" className="active">
                                        {this.props.name}
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            </Col>
                        </Row>
                    </div>

                    <Row>
                        <Col md='6'>
                            <ReactPlayer
                                height='100%'
                                width='100%'
                                ref={this.player}
                                url='https://oureye-client-notification-video-archive.s3.ap-south-1.amazonaws.com/videos/vlc-record-2021-08-27-12h00m59s-rtmp___stream.oureye.ai_show_TNDRC-MAATNIN001_live-.mp4'
                                stopOnUnmount
                                // light={true}
                                playing={this.state.playing}
                                onProgress={(obj) => {
                                    this.setState({
                                        currentTime: obj.playedSeconds,
                                        showDataForThisMinute: Math.floor(obj.playedSeconds / 60) + 1,
                                    })
                                }}
                                onPlay={() => {
                                    this.setState({
                                        playing: true,
                                        status: 'Playing'
                                    })
                                }}
                                onDuration={(dur) => this._ismounted && this.setState({ duration: dur })}
                                onBuffer={() => {
                                    this.setState({
                                        // playing: false,
                                        status: 'Buffering'
                                    })
                                }}
                                onBufferEnd={() => {
                                    this.setState({
                                        playing: true,
                                        status: 'Playing'
                                    })
                                }}
                            />
                        </Col>

                        <Col md='6'>
                            <Row>
                                <span className='h4 text-white'>Duration: {`${(this.state.currentTime / 60).toFixed(2)} / ${(this.state.duration / 60).toFixed(2)} minutes`}</span>
                            </Row>
                            <Row>
                                <span className='h4 text-white'>Current Status: {this.state.status}</span>
                            </Row>
                            <Row className='mt-2'>
                                <Col>
                                    <Button
                                        size='sm'
                                        onClick={() => {
                                            this.setState({
                                                playing: !this.state.playing
                                            })
                                        }} >{this.state.playing ? 'Pause' : 'Play'}</Button>
                                    {/* </Col>
                                <Col> */}
                                    <UncontrolledDropdown>
                                        <DropdownToggle className="nav-link pr-0" color="" tag="a">
                                            <Media>
                                                <Button size='sm'>
                                                    <i className="fas fa-filter text-primary"></i>&nbsp;&nbsp;
                                                    Hour {this.state.selectedHour} to {this.state.selectedHour + 1}
                                                </Button>
                                            </Media>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            {
                                                this.state.hourList.map((item, index) => {
                                                    return (
                                                        <div key={index}>
                                                            <DropdownItem
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    this._ismounted && this.setState({ selectedHour: item })
                                                                    this._ismounted && this.fetchData({ selectedHour: item })
                                                                }}
                                                            >
                                                                <span>Hour {item} to {item + 1}</span>
                                                            </DropdownItem>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                    &nbsp;&nbsp;&nbsp;
                                </Col>
                            </Row>
                            <Row className='mt-4 mb-2'>
                                <span className='h4 text-white'>Skip to a specific minute in the video by clicking below!</span>
                            </Row>
                            <Row className='mt-2' >
                                {
                                    Object.keys(this.state.apiData)
                                        .slice(this.state.moveButtons.start, this.state.moveButtons.end)
                                        .map((item, index) => {
                                            return (
                                                <div key={index} className='mr-2 mt-2 mb-2'>
                                                    <Button
                                                        size='sm'
                                                        onClick={() => {
                                                            if (item !== 'Loading...') {
                                                                this.player.current.seekTo(item * 60, 'seconds')
                                                                this.setState({
                                                                    playing: true,
                                                                    status: 'Playing',
                                                                })
                                                            }
                                                        }} >{item}</Button>

                                                </div>
                                            )
                                        })
                                }
                            </Row>

                            <Row className='mt-2'>
                                <Col>
                                    {this.state.moveButtons.start > 0 ?
                                        <Button
                                            size='sm'
                                            onClick={() => {
                                                this.setState({
                                                    moveButtons: {
                                                        start: this.state.moveButtons.start - 10,
                                                        end: this.state.moveButtons.end - 10
                                                    }
                                                })
                                            }} >
                                            {"<<Prev"}
                                        </Button>
                                        : null}
                                </Col>
                                <Col>
                                    {this.state.moveButtons.end < Object.keys(this.state.apiData).length - 2 ?

                                        <Button
                                            size='sm'
                                            onClick={() => {
                                                this.setState({
                                                    moveButtons: {
                                                        start: this.state.moveButtons.start + 10,
                                                        end: this.state.moveButtons.end + 10
                                                    }
                                                })
                                            }} >
                                            {"Next>>"}
                                        </Button>
                                        : null}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <span className='h4 text-white'>{keys[0] === null ? null : `Showing values for ${this.state.showDataForThisMinute - 1} to ${this.state.showDataForThisMinute} minutes`}</span>
                        </Col>
                    </Row>
                    <Row>
                        {this.state.apiData[this.state.showDataForThisMinute] ?
                            keys
                                // .slice(0, length / 2)
                                .map((item, index) => {
                                    return (
                                        <div key={index}>
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <Col>
                                                <span className='h4 text-white'>{`${keys.indexOf(item) + 1}. ${item.toString().toLowerCase()} : ${this.state.apiData[this.state.showDataForThisMinute][item]}`}</span>
                                            </Col>
                                        </div>
                                    )
                                })
                            :
                            null
                        }
                    </Row>
                    <br />
                </Container>
            </div>
        )
    }
}